import React from "react";
import "./WhatsAppButton.css"; // CSS para estilização do botão

interface WhatsAppButtonProps {
  phoneNumber: string;
  message: string;
}

const WhatsAppButton = ({ message, phoneNumber }: WhatsAppButtonProps) => {
  const handleClick = () => {
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </div>
  );
};

export default WhatsAppButton;
