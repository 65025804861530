import axios from "axios";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND || `https://api.kivenda.com`, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
