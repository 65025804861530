import { getNextOpenTime } from 'utils/getNextOpenTime';

const StoreSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-store"
  >
    <path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7" />
    <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
    <path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4" />
    <path d="M2 7h20" />
    <path d="M22 7v3a2 2 0 0 1-2 2a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12a2 2 0 0 1-2-2V7" />
  </svg>
);

const StoreStatus = ({ catalogo }) => {
  const nextOpenTime = getNextOpenTime(catalogo?.operator_time);

  return (
    <>
      {!nextOpenTime?.isOpen && (
        <>
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="absolute top-1/2 left-1/2 md:left-10 transform -translate-y-1/2 -translate-x-1/2 md:translate-x-0">
            <div className="flex gap-4">
              <div className="hidden md:flex border-[1px] border-white rounded-full p-4">
                <StoreSvg />
              </div>
              <div className="flex flex-col justify-center gap-1 text-center md:text-start">
                <span className="text-white text-xl">Loja fechada</span>
                <span className="text-white">{nextOpenTime?.message}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StoreStatus;
