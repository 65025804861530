import { FC } from "react";
import Logo from "shared/Logo/Logo";
import CartDropdown from "./CartDropdown";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Link, NavLink, useLocation } from "react-router-dom";
import OperationTime from "./OperationTime";
import { Tag } from "antd";
import { isOpenWorkTime } from "utils/isOpenWorkTime";

export interface MobileHeaderProps {}

const MobileHeader: FC<MobileHeaderProps> = () => {
  const location = useLocation();

  const catalogo = useSelector(
    (state: RootState) => state.catalogo.data.catalogo
  );
  const programPoints = useSelector(
    (state: RootState) => state.catalogo.data.programPoints
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between ">
        {location.pathname.split("/").length - 1 > 1 && (
          <div className="flex items-center cursor-pointer mr-4">
            <Link to={catalogo.slug}>
              <span className="text-xl font-medium dark:text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-chevron-left"
                >
                  <path d="m15 18-6-6 6-6" />
                </svg>
              </span>
            </Link>
          </div>
        )}

        <div
          className="lg:flex-1 flex items-center cursor-pointer"
          onClick={scrollToTop}
        >
          {/* <Logo className="flex-shrink-0" /> */}
          <span className="text-xl font-medium dark:text-white">
            {catalogo?.name || "Catálogo"}
          </span>
        </div>

        {catalogo && catalogo?.type_view !== "catalog_view" && (
          <div className="flex-[2] hidden lg:flex justify-center mx-4">
            {/* Show icon of orders */}
            <ul className="nc-Navigation flex items-center">
              <li
                className={`menu-item flex-shrink-0 menu-megamenu menu-megamenu--large`}
              >
                <div className="h-20 flex-shrink-0 flex items-center">
                  <NavLink
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"
                    to={`/${
                      window.location.pathname.split("/")[1]
                    }/meus-pedidos`}
                  >
                    Meus pedidos
                  </NavLink>
                </div>
              </li>
              {!!programPoints?.activateProgram && (
                <li
                  className={`menu-item flex-shrink-0 menu-megamenu menu-megamenu--large`}
                >
                  <div className="h-20 flex-shrink-0 flex items-center">
                    <NavLink
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"
                      to={`/${
                        window.location.pathname.split("/")[1]
                      }/programa-de-pontos`}
                    >
                      Pontos
                    </NavLink>
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}

        <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
          {/* <AvatarDropdown /> */}

          {catalogo && (
            <>
              {isOpenWorkTime(catalogo.operator_time) ? (
                <Tag color="green" bordered={false}>Aberto</Tag>
              ) : (
                <Tag color="red" bordered={false}>Fechado</Tag>
              )}
            </>
          )}
          {catalogo && <OperationTime />}
          {catalogo && catalogo?.type_view !== "catalog_view" && (
            <CartDropdown />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="nc-HeaderLogged sticky top-0 w-full z-40 bg-white dark:bg-mobile-dark-primary">
      <div className="nc-MainNav2Logged relative z-10 ">
        <div className="container ">{renderContent()}</div>
      </div>
    </div>
  );
};

export default MobileHeader;
