import React, { FC, useCallback } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PorductsProp, Product } from "store/catalogo/reducer";
import { fireWebhook } from "services/fireWebHook";
import ModalQuickView from "components/ModalQuickView";
import { RootState } from "store";
import { useSelector } from "react-redux";

export interface CardCategory3Props {
  className?: string;
  data?: Product;
  propsProducts?: PorductsProp[];
  color?: string;
}

const CardCategory3: FC<CardCategory3Props> = ({
  className = "",
  data,
  propsProducts,
  color = "bg-yellow-50",
}) => {
  const { catalogo } = useSelector((state: RootState) => state.catalogo.data);

  const [props, setProps] = React.useState<any>(
    propsProducts?.find((item) => item.product === data?._id)
  );
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const fireEvent = useCallback(async () => {
    try {
      if (!propsProducts) return;
      if (!data) return;

      if (!props) return;

      if (
        props.tracing_events?.includes("click_to_product") &&
        props.webhook_tracing
      ) {
        await fireWebhook(props.webhook_tracing);
      }
    } catch (error) {}
  }, [data, props, propsProducts]);

  if (!data) return null;
  const { name, description, price, photo } = data;
  return (
    <Link
      to={""}
      className={`nc-CardCategory3 block ${className}`}
      data-nc-id="CardCategory3"
      onClick={fireEvent}
    >
      <div
        className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group ${
          props?.color ? `${props?.color}` : color
        }`}
      >
        <div>
          <NcImage
            src={photo}
            containerClassName="absolute inset-5 sm:inset-8"
            className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
          />
        </div>
        <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

        <div>
          <div className="absolute inset-5 sm:inset-8 flex flex-col">
            <div className="max-w-52">
              <span className={`block mb-2 text-sm text-slate-700`}>
                {name}
              </span>
              {description && (
                <h4
                  className={`max-w-36 sm:max-w-48 font-light sm:text-sm  xl:text-xl text-slate-900 `}
                  dangerouslySetInnerHTML={{
                    __html:
                      `${description}`.length > 70
                        ? `${description}`.substring(0, 70) + "..."
                        : `${description}`,
                  }}
                ></h4>
              )}
            </div>
            <div className="mt-auto">
              <ButtonSecondary
                sizeClass="py-3 px-4 sm:py-3.5 sm:px-6"
                fontSize="text-sm font-medium"
                className="nc-shadow-lg"
                onClick={() => setShowModalQuickView(true)}
              >
                {catalogo.type_view === "catalog_view"
                  ? "Descrição"
                  : "Adicionar ao carrinho"}
              </ButtonSecondary>
            </div>
          </div>
        </div>
      </div>
      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        product={data as any}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </Link>
  );
};

export default CardCategory3;
