import { AutoComplete, Form, Radio, Select } from "antd";
import Label from "components/Label/Label";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient } from "services/api";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import { RootState, useAppDispatch } from "store";
import { cartSlice } from "store/cart/reducer";
import { ValuesDefault } from "store/catalogo/reducer";
import { customerSlice } from "store/customer/reducer";
import { currencyFormatter } from "utils/currencyFormatter";

interface Props {
  isActive: boolean;
  onCloseActive: () => void;
  onOpenActive: () => void;
  setTabActive: (tab: any) => void;
}

interface IAddressDTO {
  address: string;
  neighborhood: string;
  complement: string;
  number: string;
  addressType: string;
}

const ShippingAddress: FC<Props> = ({
  isActive,
  onCloseActive,
  onOpenActive,
  setTabActive,
}) => {
  const [form] = Form.useForm();
  const optionsDelivery = Form.useWatch("optionsDelivery", form);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const dispatch = useAppDispatch();
  const catalog = useSelector(
    (state: RootState) => state.catalogo.data.catalogo
  );
  const [city, setCity] = useState<
    (ValuesDefault & { value_delivery: number }) | undefined
  >(undefined);

  useEffect(() => {
    if (!customer?._id && isActive) {
      alert("Por favor, preencha os dados do cliente");
      setTabActive("ContactInfo");
      onCloseActive();
    }
  }, [customer?._id, isActive, onCloseActive, setTabActive]);

  useEffect(() => {
    if (
      catalog?.delivery_config?.ufs &&
      catalog?.delivery_config?.ufs?.length === 1
    ) {
      form.setFieldsValue({
        uf: catalog?.delivery_config?.ufs[0],
      });
    }
  }, [catalog?.delivery_config?.ufs, city, form]);
  useEffect(() => {
    if (customer) {
      form.setFieldsValue({
        address: customer.address,
        neighborhood: customer.neighborhood,
        complement: customer.complement,
        number: customer.number,
        addressType: customer.addressType,
      });
    }
  }, [customer, form]);

  const onFinish = async (values: any) => {
    try {
      const { data } = await httpClient({
        url: `/public/mount-catalogo/customer/register/address/${customer._id}`,
        method: "POST",
        data: values,
      });
      setTabActive("PaymentMethod");
      dispatch(cartSlice.actions.changeOptionsDelivery({ optionsDelivery }));
      dispatch(customerSlice.actions.setCustomer(data));
    } catch (error) {
      console.log(error);
    }
  };

  const renderShippingAddress = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">Endereço de entrega</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className="font-semibold mt-1 text-sm">
              <span className="">
                {optionsDelivery === "receive_home"
                  ? "ADICIONAR ENDEREÇO"
                  : "Retirar no local"}
              </span>
            </div>
          </div>
          <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 border-slate-400 border-2 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Mudar
          </ButtonSecondary>
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
            isActive ? "block" : "hidden"
          }`}
        >
          <Form
            initialValues={{
              addressType: "home",
              optionsDelivery: "receive_home",
            }}
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="mb-5">
              <Label className="text-sm">Opções de entrega</Label>
              <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                <Form.Item name="optionsDelivery" noStyle>
                  <Radio.Group>
                    <Radio
                      value={"receive_home"}
                      className="text-sm font-medium"
                    >
                      Receber em casa
                    </Radio>
                    <Radio value={"pick_up"} className="text-sm font-medium">
                      Buscar no local
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            {optionsDelivery === "receive_home" && (
              <>
                {catalog?.delivery_config?.ufs &&
                  catalog?.delivery_config?.ufs?.length > 0 && (
                    <>
                      <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                        <div className="flex-1">
                          <Label className="text-sm">Estado</Label>
                          <Form.Item
                            name="uf"
                            className="mb-0"
                            rules={[
                              { required: true, message: "Campo obrigatório" },
                            ]}
                          >
                            <Select
                              className="mt-1.5"
                              disabled={
                                catalog.delivery_config.ufs.length === 1
                              }
                              placeholder="Selecione um estado"
                            >
                              {catalog.delivery_config.ufs.map((uf) => (
                                <Select.Option key={uf} value={uf}>
                                  {uf}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="sm:w-1/3">
                          <Label className="text-sm">Cidade</Label>
                          <Form.Item dependencies={["uf"]}>
                            {({ getFieldValue }) => {
                              const uf = getFieldValue("uf");

                              return (
                                <Form.Item
                                  name="city"
                                  className="mb-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Campo obrigatório",
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={!uf}
                                    className="mt-1.5"
                                    placeholder="Selecione uma cidade"
                                    onChange={(_, record) => {
                                      const obj = record as any;
                                      setCity(obj);
                                    }}
                                    showSearch
                                    options={catalog.delivery_config.values_default
                                      .filter((c) => c.uf === uf)
                                      .map((c) => ({
                                        ...c,
                                        value_delivery: c.value,
                                        label: c.city,
                                        value: c.city,
                                      }))}
                                  />
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  )}

                {/* ============ */}
                <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                  <div className="flex-1">
                    <Label className="text-sm">Endereço</Label>
                    <Form.Item
                      name="address"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        className="mt-1.5"
                        placeholder="Endereço"
                        type={"text"}
                      />
                    </Form.Item>
                  </div>
                  {city && (
                    <div className="sm:w-1/3">
                      <Label className="text-sm">Bairro</Label>
                      <Form.Item
                        name="neighborhood"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        {city.other_neighborhoods ? (
                          <AutoComplete
                            rootClassName="mt-1.5 auto-complete-custom"
                            className="mb-2"
                            showSearch
                            placeholder="Bairro"
                            onSelect={(_, option) => {
                              dispatch(
                                cartSlice.actions.addShippingToOrder({
                                  shipping: option.value_delivery_bairro,
                                })
                              );
                            }}
                            onChange={(value) => {
                              if (
                                value !== "" &&
                                !city.bairros?.includes(value)
                              ) {
                                dispatch(
                                  cartSlice.actions.addShippingToOrder({
                                    shipping: city.value_delivery,
                                  })
                                );
                              } else {
                                const bairroFinder = city?.bairros?.find(
                                  (b) =>
                                    `${b.name}`.toLocaleLowerCase() ===
                                    `${value}`.toLocaleLowerCase()
                                );
                                if (bairroFinder) {
                                  dispatch(
                                    cartSlice.actions.addShippingToOrder({
                                      shipping: bairroFinder.value,
                                    })
                                  );
                                }
                              }
                            }}
                            options={(city.bairros || []).map((b) => ({
                              label: `${b.name} - ${currencyFormatter(
                                b.value
                              )}`,
                              value: b.name,
                              value_delivery_bairro: b.value,
                              value_delivery: city.value_delivery,
                            }))}
                            filterOption={(inputValue, option) =>
                              option!.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          />
                        ) : (
                          <Select
                            className="mt-1.5"
                            placeholder="Bairro"
                            showSearch
                            onSelect={(_, option) => {
                              dispatch(
                                cartSlice.actions.addShippingToOrder({
                                  shipping: option.value_delivery_bairro,
                                })
                              );
                            }}
                            options={city?.bairros?.map((b) => ({
                              label: `${b.name} - ${currencyFormatter(
                                b.value
                              )}`,
                              value: b.name,
                              value_delivery_bairro: b.value,
                              value_delivery: city.value_delivery,
                            }))}
                            filterOption={(inputValue, option) =>
                              option!.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  )}
                </div>

                {/* ============ */}
                {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
              <div>
                <Label className="text-sm">Estado</Label>
                <Select className="mt-1.5" defaultValue="United States ">
                  <option value="United States">United States</option>
                  <option value="United States">Canada</option>
                  <option value="United States">Mexico</option>
                  <option value="United States">Israel</option>
                  <option value="United States">France</option>
                  <option value="United States">England</option>
                  <option value="United States">Laos</option>
                  <option value="United States">China</option>
                </Select>
              </div>
              <div>
                <Label className="text-sm">Cidade</Label>
                <Input className="mt-1.5" defaultValue="Norris" />
              </div>
            </div> */}

                {/* ============ */}
                <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                  <div className="flex-1">
                    <Label className="text-sm">Complemento</Label>
                    <Form.Item
                      name="complement"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        className="mt-1.5"
                        placeholder="Informe um complemento"
                        type={"text"}
                      />
                    </Form.Item>
                  </div>
                  <div className="sm:w-1/3">
                    <Label className="text-sm">Numero</Label>
                    <Form.Item
                      name="number"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input className="mt-1.5" />
                    </Form.Item>
                  </div>
                </div>

                {/* ============ */}
                <div>
                  <Label className="text-sm">Tipo de Endereço</Label>
                  <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                    <Form.Item
                      name="addressType"
                      rules={[
                        {
                          required: true,
                          message: "Selecione o tipo de endereço",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={"home"} className="text-sm font-medium">
                          Casa
                        </Radio>
                        <Radio
                          value={"apartment"}
                          className="text-sm font-medium"
                        >
                          Apartamento
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </Form>

          {/* ============ */}
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={() => form.submit()}
            >
              Salvar e Prosseguir
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cancelar
            </ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
