import { StarIcon } from "@heroicons/react/24/solid";
import { productImgs } from "contains/fakeData";
import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import ModalQuickView from "./ModalQuickView";
import { ProductsMounts } from "containers/PageCollection";
import { fireWebhook } from "services/fireWebHook";
import { Button, Card } from "antd";
import { currencyFormatter } from "utils/currencyFormatter";
import Meta from "antd/es/card/Meta";

export interface CollectionCard2MobileProps {
  className?: string;
  imgs?: string[];
  name?: string;
  price?: number;
  pricePromo?: number;
  description?: string;
  data?: ProductsMounts;
}

const CollectionCard2Mobile: FC<CollectionCard2MobileProps> = ({
  className,
  imgs = [productImgs[9], productImgs[10], productImgs[11], productImgs[8]],
  name = "Product Name",
  description = "Product Description",
  price,
  pricePromo,
  data,
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const fireEvent = useCallback(async () => {
    try {
      if (!data) return;
      // Event to Product
      if (data?.props.length) {
        const props = data?.props[0];

        if (!props) return;

        if (
          props.tracing_events?.includes("click_to_product") &&
          props.webhook_tracing
        ) {
          await fireWebhook(props.webhook_tracing, { product: props.product });
        }

        return;
      }
      // Event to Category
      if (
        data.propsCategory?.tracing_events?.length &&
        data.propsCategory?.tracing_events?.includes("click_to_product") &&
        data.propsCategory?.webhook_tracing
      ) {
        await fireWebhook(data.propsCategory?.webhook_tracing);
      }
    } catch (error) {}
  }, [data]);

  return (
    <div
      className={`CollectionCard2Mobile group relative cursor-pointer  ${className}`}
    >
      <div className="  overflow-hidden   flex justify-center  ">
        <img
          onClick={async () => {
            setShowModalQuickView(true);
            await fireEvent();
          }}
          style={{ zIndex: 9999 }}
          src={data?.photo}
          alt="Card cover"
          className="h-44 w-44 object-cover rounded-full "
        />
      </div>
      <Card
        onClick={async () => {
          setShowModalQuickView(true);
          await fireEvent();
        }}
        className="shadow-sm mb-6 max-w-52 border-none bg-mobile-primary dark:bg-mobile-dark-secondary  rounded-3xl z-30"
        style={{ border: "none", marginTop: -120 }}
        styles={{
          body: {
            padding: 12,
            paddingBottom: 15,
            fontFamily: "Poppins",
          },
        }}
      >
        <div className="flex flex-col gap-3 items-center">
          <div className="h-28 "></div>
          <Meta title={name} style={{ fontSize: 18 }} />

          <span className="font-semibold text-[14px] !leading-none">
            {currencyFormatter(data?.for_price || data?.price)}
          </span>

          <span className="text-[12px] text-gray-500">
            {description.length > 24
              ? description.substring(0, 24) + "..."
              : description}
          </span>

          <Button
            style={{
              borderRadius: 40,
              border: "none",
            }}
            className="dark:bg-mobile-dark-ternary"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-plus"
              >
                <path d="M5 12h14" />
                <path d="M12 5v14" />
              </svg>
            }
          >
            {/* {catalogo.type_view === "catalog_view"
                  ? "Descrição"
                  : "Adicionar ao carrinho"} */}
          </Button>
        </div>
      </Card>

      <ModalQuickView
        show={showModalQuickView}
        product={data}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </div>
  );
};

export default CollectionCard2Mobile;
