export function getNextOpenTime(schedule) {
  const now = new Date();
  const currentDayIndex = now.getDay(); 
  const currentTime = now.toTimeString().slice(0, 5);

  const timeToMinutes = time => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  function isOpenToday(turnos) {
    const nowInMinutes = timeToMinutes(currentTime);
    return turnos.some(({ inicio, fim }) => {
      const inicioInMinutes = timeToMinutes(inicio);
      const fimInMinutes = timeToMinutes(fim);
      if (fimInMinutes < inicioInMinutes) {
        return nowInMinutes >= inicioInMinutes || nowInMinutes < fimInMinutes;
      }
      return nowInMinutes >= inicioInMinutes && nowInMinutes <= fimInMinutes;
    });
  }

  const todaySchedule = schedule[currentDayIndex];
  if (todaySchedule.active && isOpenToday(todaySchedule.turno)) {
    return { isOpen: true, message: 'Está aberto agora' };
  }

  for (let i = 0; i < 7; i++) {
    const dayIndex = (currentDayIndex + i) % 7;
    const { dia, active, turno } = schedule[dayIndex];

    if (!active) continue;

    for (const { inicio } of turno) {
      const inicioInMinutes = timeToMinutes(inicio);
      const nowInMinutes = timeToMinutes(currentTime);

      if (i === 0 && inicioInMinutes <= nowInMinutes) {
        continue; 
      }

      const message = i === 0 
        ? `Abre às ${inicio}` 
        : `Abre ${dia?.split('-')[0]?.toLowerCase()} às ${inicio}`;

      return { isOpen: false, message };
    }
  }

  return { isOpen: false, message: 'Não há horário de abertura disponível' };
}
