export function isOpenWorkTime(horarios) {
  try {
    const agora = new Date();
    const diaSemana = agora.getDay(); // 0 = Domingo, 1 = Segunda-Feira, ..., 6 = Sábado
    const horaAtual = `${agora.getHours().toString().padStart(2, "0")}:${agora
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const agoraEmMinutos = timeToMinutes(horaAtual);

    const diaAtual = horarios.find((dia) => dia.index === diaSemana);

    if (!diaAtual || !diaAtual.active) {
      return false; // Se o dia não está ativo, retorna false
    }

    // Verifica cada turno do dia
    for (let turno of diaAtual.turno) {
      const inicioEmMinutos = timeToMinutes(turno.inicio);
      const fimEmMinutos = timeToMinutes(turno.fim);

      // Se o turno cruza a meia-noite
      if (fimEmMinutos < inicioEmMinutos) {
        if (agoraEmMinutos >= inicioEmMinutos || agoraEmMinutos < fimEmMinutos) {
          return true;
        }
      } else {
        // Turno no mesmo dia
        if (agoraEmMinutos >= inicioEmMinutos && agoraEmMinutos <= fimEmMinutos) {
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    console.error("Erro ao verificar horário de funcionamento", error);
    return false;
  }
}
