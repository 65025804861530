import { Form, DatePicker } from "antd";
import Label from "components/Label/Label";
import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";
import { MaskedInput } from "antd-mask-input";
import { httpClient } from "services/api";
import { RootState, useAppDispatch } from "store";
import { customerSlice } from "store/customer/reducer";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

interface Props {
  isActive: boolean;
  isRegisterToProgramPoints?: boolean;
  onOpenActive: () => void;
  onCloseActive: () => void;
  setTabActive?: (value: any) => void;
}

const ContactInfo: FC<Props> = ({
  isActive,
  onCloseActive,
  onOpenActive,
  setTabActive,
  isRegisterToProgramPoints,
}) => {
  const [form] = Form.useForm();
  const name = Form.useWatch("name", form);
  const phone = Form.useWatch("phone", form);
  const [numberIsChecked, setNumberIsChecked] = React.useState(false);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const dispatch = useAppDispatch();

  const onFinish = async (values: any) => {
    try {
      let customerObj = {
        ...customer,
        slug: window.location.pathname.split("/")[1],
        ...values,
      };

      if (customer.phone !== values.phone) {
        customerObj = {
          ...values,
          slug: window.location.pathname.split("/")[1],
        };
      }

      const { data } = await httpClient({
        url: `/public/mount-catalogo/customer/register`,
        method: "POST",
        data: customerObj,
      });
      dispatch(customerSlice.actions.setCustomer(data));
      onCloseActive();
    } catch (error) {}
  };

  useEffect(() => {
    if (customer && customer?.name) {
      form.setFieldsValue({
        phone: customer.phone,
        name: customer.name,
        email: customer.email,
        birthday: customer.birthday ? dayjs(customer.birthday) : undefined,
        receive_email: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, form]);

  useEffect(() => {
    if (phone && phone.length > 14) {
      setNumberIsChecked(true);
      const getUserByPhone = async () => {
        try {
          const { data } = await httpClient({
            url: `/public/mount-catalogo/customer/by-phone`,
            method: "GET",
            params: {
              catalog: window.location.pathname.split("/")[1],
              phone,
            },
          });
          if (setTabActive) setTabActive("ShippingAddress");
          dispatch(customerSlice.actions.setCustomer(data));
        } catch (error) {
          dispatch(customerSlice.actions.setCustomer({}));
        }
      };
      getUserByPhone();
    } else {
      setNumberIsChecked(false);
    }
  }, [dispatch, phone, setTabActive]);

  const renderAccount = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
        <div
          className={`flex flex-col sm:flex-row items-start p-6 ${
            isRegisterToProgramPoints && "hidden"
          }`}
        >
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase tracking-tight">Suas informações</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className="font-semibold mt-1 text-sm">
              <span className="">{name}</span>
              <span className="ml-3 tracking-tighter">{phone}</span>
            </div>
          </div>
          <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 border-slate-400 border-2 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={() => onOpenActive()}
          >
            Mudar
          </ButtonSecondary>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
              isActive ? "block" : "hidden"
            }`}
          >
            <div className="flex justify-between flex-wrap items-baseline">
              <h3 className="text-lg font-semibold">Informações básicas</h3>
              {/* <span className="block text-sm my-1 md:my-0">
              Do not have an account?{` `}
              <a href="##" className="text-primary-500 font-medium">
                Log in
              </a>
            </span> */}
            </div>
            <div className="max-w-lg">
              <Form.Item
                name={"phone"}
                label="Telefone"
                rules={[
                  {
                    required: true,
                    message: "Por favor, informe seu telefone",
                  },
                ]}
              >
                <MaskedInput
                  className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:text-inherit dark:focus:ring-primary-6000 dark:placeholder-gray-500 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5"
                  mask={[{ mask: "(00) 00000-0000" }]}
                  placeholder="DDD + Telefone"
                  maskOptions={{
                    dispatch: function (_, dynamicMasked) {
                      return dynamicMasked.compiledMasks[0];
                    },
                  }}
                />
              </Form.Item>
            </div>
            <div className="max-w-lg">
              <Form.Item
                label="Nome"
                name={"name"}
                rules={[
                  { required: true, message: "Por favor, informe seu nome" },
                ]}
              >
                <Input
                  className="mt-1.5 placeholder-gray-500"
                  placeholder="Seu nome"
                  disabled={!numberIsChecked}
                />
              </Form.Item>
            </div>

            <div className="max-w-lg">
              <Label className="text-sm">Email</Label>
              <Form.Item name={"email"}>
                <Input
                  className="mt-1.5 placeholder-gray-500"
                  type={"email"}
                  placeholder="Seu e-mail"
                  disabled={!numberIsChecked}
                />
              </Form.Item>
            </div>
            {isRegisterToProgramPoints && (
              <div className="max-w-lg">
                <Label className="text-sm">Data de aniversário</Label>
                <Form.Item
                  name={"birthday"}
                  rules={[
                    {
                      required: true,
                      message: "Por favor, informe sua data de aniversário",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={!numberIsChecked}
                    format={"DD/MM/YYYY"}
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:text-inherit dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5"
                  />
                </Form.Item>
              </div>
            )}
            <div>
              <Form.Item name={"receive_email"} valuePropName="checked">
                <Checkbox
                  className="!text-sm"
                  name="receive-email"
                  label="Gostaria de receber ofertas exclusivas por e-mail?"
                />
              </Form.Item>
            </div>

            {/* ============ */}
            {isRegisterToProgramPoints ? (
              <ButtonPrimary className="sm:!px-7 shadow-none">
                Cadastrar no programa de pontos
              </ButtonPrimary>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row pt-6">
                  <ButtonPrimary className="sm:!px-7 shadow-none">
                    Salvar informações
                  </ButtonPrimary>
                  <ButtonSecondary
                    className="mt-3 sm:mt-0 sm:ml-3"
                    onClick={() => onCloseActive()}
                  >
                    Cancelar
                  </ButtonSecondary>
                </div>
              </>
            )}
          </div>
        </Form>
      </div>
    );
  };

  return renderAccount();
};

export default ContactInfo;
