import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import SectionPromo1 from "components/SectionPromo1";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import {
  PorductsProp,
  Product,
  ProductCategoryCatalogo,
} from "store/catalogo/reducer";
import { productValidToView } from "utils/productValidToView";
import ScrollSpy from "react-ui-scrollspy";
import { cartSlice } from "store/cart/reducer";
import DiscoverMoreSliderMobile from "components/DiscoverMoreSliderMobile";
import ListCardMobile from "components/ListCardMobile";
import ProductsSliderMobile from "components/ProductsSliderMobile";
import StoreStatus from "components/StoreStatus";

export interface PageCollectionMobileProps {
  className?: string;
}

const SliderCategories = ({ categories }: { categories: GroupProducts[] }) => {
  const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        top: 62,
      }}
    >
      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          padding: "0 0px",
        }}
      >
        {categories.map((item, index) => (
          <a
            className="cursor-pointer"
            key={index}
            onClick={(e) => onPress(e)}
            href={`#${item.category._id}`}
          >
            <div
              data-to-scrollspy-id={item.category._id}
              className={"m-[10px] dark:text-white text-sm font-[500]"}
            >
              {item.category.name}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export interface ProductsMounts extends Product {
  props: PorductsProp[];
  propsCategory: {
    tracing_events: string[];
    webhook_tracing: string;
  };
}

export interface GroupProducts {
  category: ProductCategoryCatalogo;
  products: Array<ProductsMounts>;
}

const PageCollectionMobile: FC<PageCollectionMobileProps> = ({
  className = "",
}) => {
  const { catalogo, productCategoryCatalogo, programPoints } = useSelector(
    (state: RootState) => state.catalogo.data
  );
  const customer = useSelector((state: RootState) => state.customer.customer);
  const [categories, setCategories] = React.useState<Array<GroupProducts>>([]);
  const [exibirProduts, setExibirProduts] = React.useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // get query params
    const urlParams = new URLSearchParams(window.location.search);
    const tableParam = urlParams.get("mesa");

    if (tableParam) {
      dispatch(cartSlice.actions.setTable({ table: tableParam }));
    }

    setTimeout(() => {
      setExibirProduts(true);
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    const reduceCategories = productCategoryCatalogo.filter(
      (c) => c.products.length > 0 && !c.isFixed
    );

    const categoriesWithProducts = reduceCategories.map((category) => {
      return {
        category,
        products: category.products.map((product) => {
          return {
            ...product,
            propsCategory: {
              tracing_events: category.tracing_events,
              webhook_tracing: category.webhook_tracing,
            },
            props: category.products_props.filter(
              (p) => p.product === product._id
            ),
          };
        }),
      };
    }) as unknown as Array<GroupProducts>;

    setCategories(categoriesWithProducts);
  }, [productCategoryCatalogo]);

  return (
    <div
      className={`nc-PageCollectionMobile relative overflow-hidden ${className} bg`}
      data-nc-id="PageCollectionMobile"
    >
      <Helmet>
        <title>{catalogo?.name}</title>
      </Helmet>
      <div className="relative top-0 mt-0 lg:block">
        <img
          className="w-full object-contain "
          src={catalogo?.thumbnail || ""}
          alt={catalogo?.name}
        />

        {catalogo?.thumbnail && (
          <StoreStatus catalogo={catalogo}/>
        )} 
      </div>
      <div className="mt-4 lg:mt-9 ">
        <div className="">
          <DiscoverMoreSliderMobile />
        </div>
      </div>
      <div className="container  lg:pb-28 lg:pt-1 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div
            id="sticky-banner"
            tabIndex={-1}
            className="container-scroll-slider sm:hidden fixed max-h-12 top-[70px] start-0 z-50 flex justify-between w-full p-4 bg-white  dark:text-white dark:bg-mobile-dark-primary  "
          >
            <div className="">
              <SliderCategories categories={categories} />
            </div>
          </div>
          <main>
            {exibirProduts && (
              <ScrollSpy
                activeClass={"active-scroll-spy-mobile"}
                scrollThrottle={100}
                onUpdateCallback={(id) => {
                  try {
                    const idTarget = '[data-to-scrollspy-id="' + id + '"]';
                    const target = document.querySelector(idTarget);
                    if (target) {
                      target.scrollIntoView({ behavior: "smooth" });
                    }
                  } catch (error) {}
                }}
              >
                {/* LOOP ITEMS */}
                {categories.map((item, index) => {
                  if (item.category.type_category === "carousel") {
                    return <ProductsSliderMobile groupData={item} />;
                  }

                  return (
                    <div
                      id={item.category._id}
                      key={index}
                      className="element-main py-100"
                    >
                      <div className="mb-10 ">
                        <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-10 ">
                          {item.category.name}
                        </h2>
                        <div>
                          {item.products
                            .filter((p) => productValidToView(p))
                            .map((product, index) => {
                              return (
                                <ListCardMobile data={product} key={index} />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </ScrollSpy>
            )}
          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderCollections />
        <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {programPoints && programPoints.activateProgram && (
          <>{customer && customer?._id ? null : <SectionPromo1 />}</>
        )}
      </div>
    </div>
  );
};

export default PageCollectionMobile;
