import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { gerarNrPedido } from "utils/makeNumberOrder";
export interface ProductToOrder {
  _id: string;
  menus: string[];
  name: string;
  description: string;
  stock: number;
  check_stock: string;
  low_stock_alert: number;
  quantity: number;
  price: number;
  photo: string;
  enable_days: any[];
  created: string;
  updated: string;
  __v: number;
  complements: Complement[];
  total_value: number;
  total_value_unit: number;
  finish_value_unit: number;
  finish_value: number;
  discountPercent: number;
  discountPercent_unit: number;
  discountMoney: number;
  discountMoney_unit: number;
}

export interface ComplementItem {
  _id: string;
  name: string;
  description: string;
  photo: string;
  group_id: string;
  created: string;
  updated: string;
  price?: number;
  __v: number;
}

export interface ProductToCartAdd {
  _id: string;
  menus: string[];
  name: string;
  description: string;
  price: number;
  for_price?: number;
  photo: string;
  enable_days: any[];
  created: string;
  check_stock: string;
  low_stock_alert: number;
  enable_hours_init?: string;
  enable_hours_end?: string;
  updated: string;
  complements: ComplementGroup[];
  __v: number;
}

export interface ComplementGroup {
  _id: string;
  name: string;
  type: string;
  type_summarization: string;
  min_select: number;
  max_select: number;
  itens: ComplementItem[];
  created: string;
  updated: string;
  __v: number;
}

interface Complement {
  _id: string;
  name: string;
  type: string;
  type_summarization: string;
  min_select: number;
  max_select: number;
  itens: Iten[];
  created: string;
  updated: string;
  __v: number;
}

interface Iten {
  _id: string;
  name: string;
  price: number;
  group_id: string;
  created: string;
  updated: string;
  __v: number;
}

export interface ICoupons {
  _id: string;
  menus: string[];
  title: string;
  code: string;
  expires_at: Date;
  discount_pc: number;
  max_value_discount_in_order: number;
  max_use: number;
  count_max_use: number;
  active: boolean;
}

interface IHistoricOrder {
  new_status: string;
  old_status: string;
  user_change: string;
  date: string;
}

export interface IOrder {
  _id?: string;
  customer?: { _id: string };
  nr: string;
  status?: string;
  optionsDelivery?: string;
  history?: IHistoricOrder[];
  products: ProductToOrder[];
  menu: string;
  referrer_code?: string;
  value: number;
  discount: number;
  coupon: ICoupons | null;
  coupon_value_discount?: number;
  discount_order: number;
  finish_value: number;
  shipping: number;
  method_payment?: string;
  table?: string;
  value_payment_cash?: number;
  value_payment_cash_change?: number;
  createdAt?: string;
}

export interface initialStateCart {
  order: IOrder;
  table?: string;
}

function recalcOrder(order: IOrder) {
  order.value = order.products.reduce(
    (acc, product) => acc + product.total_value,
    0
  );

  order.finish_value =
    order.products.reduce((acc, product) => acc + product.finish_value, 0) +
    order.shipping -
    order.discount_order;

  // calculo de desconto do cupom
  const totolDiscountByCoupon =
    (order.finish_value * (order.coupon?.discount_pc || 0)) / 100;
  if (
    order?.coupon?.max_value_discount_in_order &&
    totolDiscountByCoupon > order.coupon.max_value_discount_in_order
  ) {
    order.coupon_value_discount = order.coupon.max_value_discount_in_order;
    order.finish_value -= order.coupon.max_value_discount_in_order;
  } else {
    order.coupon_value_discount = totolDiscountByCoupon;
    order.finish_value -= totolDiscountByCoupon;
  }

  order.discount =
    order.products.reduce((acc, product) => acc + product.discountMoney, 0) +
    order.discount_order;

  return order;
}

const initialState: initialStateCart = {
  order: {
    nr: gerarNrPedido(),
    products: [],
    menu: "",
    value: 0,
    discount: 0,
    coupon: null,
    discount_order: 0,
    finish_value: 0,
    shipping: 0,
  },
  table: undefined,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setTable: (state, action: PayloadAction<{ table: string }>) => {
      state.table = action.payload.table;
      if (state.order) state.order.table = action.payload.table;
    },
    changeOptionsDelivery(
      state,
      action: PayloadAction<{ optionsDelivery: string }>
    ) {
      let order = state.order;
      if (order) {
        order.optionsDelivery = action.payload.optionsDelivery;
      }
    },
    changePaymentMethod: (state, action: PayloadAction<{ method: string }>) => {
      let order = state.order;
      if (order) {
        order.method_payment = action.payload.method;
      }
    },
    removeCounponOfOrder: (state) => {
      let order = state.order;
      if (order) {
        order.coupon = null;
        order = recalcOrder(order);
      }
    },
    removeReferrerCode: (state) => {
      let order = state.order;
      if (order) {
        order.referrer_code = undefined;
      }
    },
    changeReferrerCode: (state, action: PayloadAction<{ code: string }>) => {
      let order = state.order;
      if (order) {
        order.referrer_code = action.payload.code;
      }
    },
    changeCouponOfOrder: (
      state,
      action: PayloadAction<{ coupon: ICoupons }>
    ) => {
      let order = state.order;
      if (order) {
        order.coupon = action.payload.coupon;
        order = recalcOrder(order);
      }
    },
    decrementProductQuantity: (
      state,
      action: PayloadAction<{ product: ProductToOrder }>
    ) => {
      let order = state.order;
      if (order) {
        const product = order.products.find(
          (product) => product._id === action.payload.product._id
        );
        if (product && product.quantity > 1) {
          product.quantity--;
          product.finish_value = product.finish_value_unit * product.quantity;
          product.total_value = product.total_value_unit * product.quantity;
          product.discountMoney = product.discountMoney_unit * product.quantity;
          product.discountPercent =
            product.discountPercent_unit * product.quantity;
        }
        order = recalcOrder(order);
      }
    },
    addShippingToOrder: (
      state,
      action: PayloadAction<{ shipping: number }>
    ) => {
      let order = state.order;
      if (order) {
        order.shipping = action.payload.shipping;
        order = recalcOrder(order);
      }
    },
    incrementProductQuantity: (
      state,
      action: PayloadAction<{ product: ProductToOrder }>
    ) => {
      let order = state.order;
      if (order) {
        const product = order.products.find(
          (product) => product._id === action.payload.product._id
        );

        if (
          product?.check_stock === "yes" &&
          product?.stock <= product?.quantity
        ) {
          return;
        }

        if (product) {
          product.quantity++;
          product.finish_value = product.finish_value_unit * product.quantity;
          product.total_value = product.total_value_unit * product.quantity;
          product.discountMoney = product.discountMoney_unit * product.quantity;
          product.discountPercent =
            product.discountPercent_unit * product.quantity;
        }
        order = recalcOrder(order);
      }
    },
    removeProductFromOrder: (
      state,
      action: PayloadAction<{ product: ProductToOrder }>
    ) => {
      let order = state.order;
      if (order) {
        order.products = order.products.filter(
          (product) => product._id !== action.payload.product._id
        );

        order = recalcOrder(order);
      }
    },
    addProductToOrder: (
      state,
      action: PayloadAction<{ product: ProductToOrder }>
    ) => {
      let order = state.order;
      if (order) {
        order.products.push(action.payload.product);

        order = recalcOrder(order);
      }
    },
    newOrder: (state) => {
      state.order = {
        nr: gerarNrPedido(),
        products: [],
        table: state.table,
        menu: "",
        value: 0,
        discount: 0,
        coupon: null,
        discount_order: 0,
        finish_value: 0,
        shipping: 0,
      };
    },
  },
});
