import React, { FC, useCallback, useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BagIcon from "./BagIcon";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import { ProductsMounts } from "containers/PageCollection";
import { fireWebhook } from "services/fireWebHook";
import { RootState } from "store";
import { useSelector } from "react-redux";

export interface ProductCardProps {
  className?: string;
  data?: ProductsMounts;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  isLiked,
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const { catalogo } = useSelector((state: RootState) => state.catalogo.data);
  useEffect(() => {
    const reducerTagProduct = data?.props?.reduce((acc, item) => {
      if (item.tag_product) {
        acc = item.tag_product || "";
      }
      return acc;
    }, "");
    setStatus(reducerTagProduct as string);
  }, [data?.props]);

  const renderGroupButtons = () => {
    return (
      <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
        <ButtonPrimary
          className="shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          // onClick={() => notifyAddTocart({ size: "XL" })}
        >
          {catalogo.type_view === "catalog_view" ? (
            "Descrição"
          ) : (
            <>
              <BagIcon className="w-3.5 h-3.5 mb-0.5" />
              <span className="ml-1">Adicionar</span>
            </>
          )}
        </ButtonPrimary>
        {/* <ButtonSecondary
          className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => setShowModalQuickView(true)}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">Quick view</span>
        </ButtonSecondary> */}
      </div>
    );
  };

  const fireEvent = useCallback(async () => {
    try {
      if (!data) return;
      // Event to Product
      if (data?.props.length) {
        const props = data?.props[0];

        if (!props) return;

        if (
          props.tracing_events?.includes("click_to_product") &&
          props.webhook_tracing
        ) {
          await fireWebhook(props.webhook_tracing, { product: props.product });
        }

        return;
      }
      // Event to Category
      if (
        data.propsCategory?.tracing_events?.length &&
        data.propsCategory?.tracing_events?.includes("click_to_product") &&
        data.propsCategory?.webhook_tracing
      ) {
        await fireWebhook(data.propsCategory?.webhook_tracing);
      }
    } catch (error) {}
  }, [data]);

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent cursor-pointer ${className}`}
        data-nc-id="ProductCard"
        onClick={async () => {
          setShowModalQuickView(true);
          await fireEvent();
        }}
      >
        <div className="relative flex-shrink-0 bg-slate-50  dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-6 object-cover w-full h-0"
            src={data?.photo}
            className="object-contain w-full h-full drop-shadow-xl"
          />

          <ProductStatus status={status} />

          {/* <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" /> */}

          {renderGroupButtons()}
        </div>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
          {/* {renderVariants()} */}

          <div>
            <h2
              className={`nc-ProductCard__title text-base font-semibold transition-colors`}
            >
              {`${data?.name}`.substring(0, 30)}
            </h2>
            <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
              {`${data?.description}`.length > 30
                ? `${data?.description}`.substring(0, 30) + "..."
                : `${data?.description}`}
            </p>
          </div>

          <div className="flex justify-between items-end ">
            <Prices price={data?.price} pricePromo={data?.for_price} />
            {/* <div className="flex items-center mb-0.5">
              <StarIcon className="w-5 h-5 pb-[1px] text-amber-400" />
              <span className="text-sm ml-1 text-slate-500 dark:text-slate-400">
                {(Math.random() * 1 + 4).toFixed(1)} (
                {Math.floor(Math.random() * 70 + 20)} reviews)
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        product={data}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;
