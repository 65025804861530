import StepsOrder from "components/StepsOrder";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { httpClient } from "services/api";
import { io } from "socket.io-client";
import { IOrder } from "store/cart/reducer";

let socketIsReady = false;

const Order = () => {
  const [order, setOrder] = useState<IOrder>();
  const { slug, id } = useParams();

  useEffect(() => {
    if (!id || socketIsReady) {
      return;
    }

    const socket = io(
      process.env.REACT_APP_URL_BACKEND ||
        "https://api.kivenda.com"
    );

    socket.on("connect", () => {
      socketIsReady = true;

      socket.emit("joinRoom", `client-order-${id}`);
    });

    socket.on("toClient", (data: { type: string; msg: any }) => {
      if (data?.type) {
        switch (data.type) {
          case "client_order_status_change":
            setOrder(data.msg);
            break;
          default:
            console.log("default", data);
            break;
        }
      }
    });
    return () => {
      console.log("disconnecting order");
      socketIsReady = false;
      socket.disconnect();
    };
  }, [id]);

  const searchOrder = useCallback(async () => {
    try {
      const { data } = await httpClient({
        url: `/orders-catalog/${id}/${slug}`,
        method: "GET",
      });
      setOrder(data);
    } catch (error) {
      console.error(error);
    }
  }, [id, slug]);

  useEffect(() => {
    searchOrder();
  }, [searchOrder]);

  return (
    <div className="nc-Order">
      <Helmet>
        <title>Pedido #{`${order?.nr}`}</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-5 min-h-[636px]">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Pedido #{order?.nr}
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link
              to={`/${window.location.pathname.split("/")[1]}`}
              className=""
            >
              Voltar
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Meus pedidos</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full  divide-y divide-slate-200 dark:divide-slate-700 ">
            {order && <StepsOrder order={order} />}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Order;
