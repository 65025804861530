import React, { FC, useEffect, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { GroupProducts } from "containers/PageCollection";
import CollectionCard2Mobile from "./CollectionCardMobile";

export interface SectionSliderLargeProductProps {
  className?: string;
  itemClassName?: string;
  cardStyle?: "style1" | "style2";
  groupData: GroupProducts;
}

const ProductsSliderMobile: FC<SectionSliderLargeProductProps> = ({
  className = "",
  cardStyle = "style2",
  groupData,
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.15,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },

        500: {
          gap: 20,
          perView: 1,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [UNIQUE_CLASS]);

  const MyCollectionCard = CollectionCard2Mobile;

  if (groupData.products.length === 0) return null;

  return (
    <div
      id={groupData.category._id}
      className={`nc-SectionSliderLargeProduct ${className}`}
    >
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading isCenter={false} hasNextPrev>
          {groupData?.category?.name}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {groupData.products.map((product, index) => (
              <li className={`glide__slide max-w-52`} key={index}>
                <MyCollectionCard
                  data={product}
                  name={product.name}
                  price={product.price}
                  pricePromo={product.for_price}
                  imgs={product.photos}
                  description={product.description}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductsSliderMobile;
