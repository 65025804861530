import React, { FC } from "react";
import { currencyFormatter } from "utils/currencyFormatter";

export interface PricesProps {
  className?: string;
  price?: number;
  pricePromo?: number;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  pricePromo = 0,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => {
  return (
    <div className={`${className}`}>
      <div
        className={`flex items-center border-2 flex-wrap border-green-500 rounded-lg ${contentClass}`}
      >
        {
          // Price promo
          pricePromo === 0 || pricePromo === null ? (
            <span className="text-green-500 !leading-none">
              {currencyFormatter(price)}
            </span>
          ) : (
            <>
              <span className="text-gray-500 line-through !leading-none">
                {currencyFormatter(price)}
              </span>
              <span className="mx-1">por</span>
              <span className="text-green-500 !leading-none">
                {currencyFormatter(pricePromo)}
              </span>
            </>
          )
        }
      </div>
    </div>
  );
};

export default Prices;
