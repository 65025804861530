import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { PorductsProp, Product } from "store/catalogo/reducer";
import { fireWebhook } from "services/fireWebHook";
import ModalQuickView from "components/ModalQuickView";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { Button, Card } from "antd";
import Meta from "antd/es/card/Meta";
import { currencyFormatter } from "utils/currencyFormatter";

export interface FixedVerticalCardProps {
  className?: string;
  data?: Product;
  propsProducts?: PorductsProp[];
  color?: string;
}

const FixedVerticalCard: FC<FixedVerticalCardProps> = ({
  className = "",
  data,
  propsProducts,
  color = "bg-yellow-50",
}) => {
  const { catalogo } = useSelector((state: RootState) => state.catalogo.data);
  const { theme } = catalogo as any;

  const [props, setProps] = React.useState<any>(
    propsProducts?.find((item) => item.product === data?._id)
  );
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const fireEvent = useCallback(async () => {
    try {
      if (!propsProducts) return;
      if (!data) return;

      if (!props) return;

      if (
        props.tracing_events?.includes("click_to_product") &&
        props.webhook_tracing
      ) {
        await fireWebhook(props.webhook_tracing);
      }
    } catch (error) {}
  }, [data, props, propsProducts]);

  if (!data) return null;
  const { name, description, price, photo } = data;
  return (
    <Link
      to={""}
      className={`nc-FixedVerticalCard block  ${className}`}
      data-nc-id="FixedVerticalCard"
      onClick={fireEvent}
    >
      <div
        className={` rounded-3xl overflow-hidden group shadow-lg my-2 mb-4 `}
      >
        <Card
          onClick={() => setShowModalQuickView(true)}
          className="max-w-52 border-none bg-mobile-secondary dark:bg-mobile-dark-secondary "
          cover={
            <div className="h-52 w-full overflow-hidden  ">
              <img
                src={photo}
                alt="Card cover"
                className="h-full w-full object-cover"
              />
            </div>
          }
          style={{ border: "none" }}
          styles={{
            body: {
              padding: 12,
              paddingBottom: 15,
              fontFamily: "Poppins",
              background:
                theme === "dark"
                  ? "radial-gradient(65.26% 65.26% at 65% 50%, #200800 0.01%, #060100 100%)"
                  : "radial-gradient(65.26% 65.26% at 65% 50%, #FDF3E7 0.01%, #fffbf8 100%)",
            },
          }}
        >
          <div className="flex flex-col gap-3 ">
            <div className="flex flex-col gap-1 ">
              <Meta title={name} style={{ fontSize: 18 }} />
              <span className="text-[13px] text-gray-500">
                {description.length > 24
                  ? description.substring(0, 24) + "..."
                  : description}
              </span>
            </div>

            <div className="flex justify-between items-start">
              <span className="font-bold text-base !leading-none">
                {currencyFormatter(data?.for_price || data?.price)}
              </span>

              <Button
                style={{
                  borderRadius: 40,
                  border: "none",
                }}
                className="bg-mobile-secondary dark:bg-mobile-dark-secondary shadow-md"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-plus"
                  >
                    <path d="M5 12h14" />
                    <path d="M12 5v14" />
                  </svg>
                }
              >
                {/* {catalogo.type_view === "catalog_view"
                  ? "Descrição"
                  : "Adicionar ao carrinho"} */}
              </Button>
            </div>
          </div>
        </Card>
      </div>
      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        product={data as any}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </Link>
  );
};

export default FixedVerticalCard;
