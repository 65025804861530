import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RootState, useAppDispatch } from "store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getCatalogoData } from "store/catalogo/thunk/getCatalogoData.thunk";
import { ToastContainer } from "react-toastify";
import { WebSocketRootClientHandle } from "components/WebSocketRootClientHandle";
import { FireAnnouncement } from "components/Announcements";
import { ConfigProvider, theme as ThemeAntd } from "antd";
import { selectTheme } from "store/catalogo/reducer";
import ptBR from "antd/es/locale/pt_BR";
import ScrollSpy from "react-ui-scrollspy";

let executed = false;

function App() {
  const { data, loading } = useSelector((state: RootState) => state.catalogo);

  const { catalogo } = data;

  const dispatch = useAppDispatch();
  useEffect(() => {
    const slug = window.location.pathname.split("/")[1];

    if (executed) {
      return;
    }

    executed = true;

    dispatch(getCatalogoData({ slug }));
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{catalogo?.name}</title>
        <link rel="icon" href={catalogo?.photo} />
        <meta name="description" content={catalogo?.description} />
      </Helmet>

      {/* MAIN APP */}
      <div className={`bg-white text-base dark:${data?.catalogo && true ? 'bg-mobile-dark-primary' : 'bg-slate-90'}  text-slate-900 dark:text-slate-200`}>
        {loading ? (
          <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
            <div className="flex items-center justify-center space-x-2 animate-pulse">
              <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
              <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
              <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
            </div>
          </div>
        ) : (
          <div
            className={`transition-opacity duration-1000 ${
              loading ? "opacity-0" : "opacity-100"
            }`}
          >
            {/* Conteúdo principal da aplicação */}
            <MyRouter />
          </div>
        )}
      </div>
    </HelmetProvider>
  );
}

export function Wrapper() {
  const theme = useSelector(selectTheme());

  useEffect(() => {
    if (theme === "dark") {
      const root = document.querySelector("html");
      if (!root) return;
      !root.classList.contains("dark") && root.classList.add("dark");
    }
  }, [theme]);

  return (
    <>
      <ConfigProvider
        locale={ptBR}
        theme={{
          algorithm:
            theme === "light"
              ? ThemeAntd.defaultAlgorithm
              : ThemeAntd.darkAlgorithm,
        }}
      >
        <ToastContainer theme={theme} />
        <WebSocketRootClientHandle />
        <FireAnnouncement />
        <App />
      </ConfigProvider>
    </>
  );
}

export default App;
