import { StarIcon } from "@heroicons/react/24/solid";
import { productImgs } from "contains/fakeData";
import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import ModalQuickView from "./ModalQuickView";
import { ProductsMounts } from "containers/PageCollection";
import { fireWebhook } from "services/fireWebHook";

export interface CollectionCard2Props {
  className?: string;
  imgs?: string[];
  name?: string;
  price?: number;
  pricePromo?: number;
  description?: string;
  data?: ProductsMounts;
}

const CollectionCard2: FC<CollectionCard2Props> = ({
  className,
  imgs = [productImgs[9], productImgs[10], productImgs[11], productImgs[8]],
  name = "Product Name",
  description = "Product Description",
  price,
  pricePromo,
  data,
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const fireEvent = useCallback(async () => {
    try {
      if (!data) return;
      // Event to Product
      if (data?.props.length) {
        const props = data?.props[0];

        if (!props) return;

        if (
          props.tracing_events?.includes("click_to_product") &&
          props.webhook_tracing
        ) {
          await fireWebhook(props.webhook_tracing, { product: props.product });
        }

        return;
      }
      // Event to Category
      if (
        data.propsCategory?.tracing_events?.length &&
        data.propsCategory?.tracing_events?.includes("click_to_product") &&
        data.propsCategory?.webhook_tracing
      ) {
        await fireWebhook(data.propsCategory?.webhook_tracing);
      }
    } catch (error) {}
  }, [data]);

  return (
    <div
      className={`CollectionCard2 group relative cursor-pointer ${className}`}
      onClick={async () => {
        setShowModalQuickView(true);
        await fireEvent();
      }}
    >
      <div className="relative flex flex-col">
        <NcImage
          containerClassName="aspect-w-8 aspect-h-5 bg-neutral-100 rounded-2xl overflow-hidden"
          className="object-cover w-full h-full rounded-2xl"
          src={data?.photo}
        />
        <div className="grid grid-cols-3 gap-2.5 mt-2.5">
          {imgs.map((img, index) => {
            if (index === 0) return null;
            return (
              <NcImage
                key={index}
                containerClassName="aspect-w-8 aspect-h-5 bg-neutral-100 rounded-2xl overflow-hidden"
                className="object-contain w-full h-full rounded-2xl"
                src={img}
              />
            );
          })}
        </div>
      </div>

      <div className="relative mt-5 flex justify-between">
        {/* TITLE */}
        <div className="flex-1">
          <h2 className="font-semibold text-lg sm:text-xl ">{name}</h2>
          {/* AUTHOR */}
          <div className="mt-3 flex items-center text-slate-500 dark:text-slate-400">
            <span className="text-sm ">
              <span className="line-clamp-1">{description}</span>
            </span>
            {/* <span className="h-5 mx-1 sm:mx-2 border-l border-slate-200 dark:border-slate-700"></span>
            <StarIcon className="w-4 h-4 text-orange-400" />
            <span className="text-sm ml-1 ">
              <span className="line-clamp-1">4.9 (269 reviews)</span>
            </span> */}
          </div>
        </div>
        <Prices
          className="mt-0.5 sm:mt-1 ml-4"
          price={price}
          pricePromo={pricePromo}
        />
      </div>

      <ModalQuickView
        show={showModalQuickView}
        product={data}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </div>
  );
};

export default CollectionCard2;
