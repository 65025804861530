import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { PorductsProp, Product } from "store/catalogo/reducer";
import { fireWebhook } from "services/fireWebHook";
import ModalQuickView from "components/ModalQuickView";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { Button, Card } from "antd";
import Meta from "antd/es/card/Meta";
import { currencyFormatter } from "utils/currencyFormatter";

export interface ListCardMobileProps {
  className?: string;
  data?: Product;
  propsProducts?: PorductsProp[];
  color?: string;
}

const ListCardMobile: FC<ListCardMobileProps> = ({
  className = "",
  data,
  propsProducts,
  color = "bg-yellow-50",
}) => {
  const { catalogo } = useSelector((state: RootState) => state.catalogo.data);
  const { theme } = catalogo as any;

  const [props, setProps] = React.useState<any>(
    propsProducts?.find((item) => item.product === data?._id)
  );
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const fireEvent = useCallback(async () => {
    try {
      if (!propsProducts) return;
      if (!data) return;

      if (!props) return;

      if (
        props.tracing_events?.includes("click_to_product") &&
        props.webhook_tracing
      ) {
        await fireWebhook(props.webhook_tracing);
      }
    } catch (error) {}
  }, [data, props, propsProducts]);

  if (!data) return null;
  const { name, description, price, photo } = data;
  return (
    <Link
      to={""}
      className={`nc-ListCardMobile block  ${className}`}
      data-nc-id="ListCardMobile"
      onClick={fireEvent}
    >
      <div
        className={` rounded-3xl overflow-hidden group shadow-lg my-2 mb-4 `}
      >
        <Card
          onClick={() => setShowModalQuickView(true)}
          className="w-full border-none bg-mobile-primary dark:bg-mobile-dark-secondary flex flex-row"
          cover={
            <div className="h-28 w-full overflow-hidden max-w-32">
              <img
                src={photo}
                alt="Card cover"
                className="h-full w-full object-cover min-w-32 "
              />
            </div>
          }
          style={{ border: "none" }}
          styles={{
            body: {
              padding: 12,
              paddingBottom: 15,
              fontFamily: "Poppins",
              width: "100%",
            },
          }}
        >
          <div className="flex flex-col justify-between  w-full  h-full">
            <div className="flex flex-col gap-1 max-w-[49vw]">
              <span className=" truncate text-base font-semibold">{name}</span>
              <span className="truncate text-[13px] text-gray-500 ">
                { description}
              </span>
            </div>
            <div className="flex justify-between ">
            <span className="font-bold text-base !leading-none mb-1">
              {currencyFormatter(data?.for_price || data?.price)}
            </span>
            <Button
              style={{
                borderRadius: 40,
                border: "none",
              }}
              className="dark:bg-mobile-dark-ternary absolute right-3 bottom-3"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-plus"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5v14" />
                </svg>
              }
            ></Button>
          </div>
          </div>
          
        </Card>
      </div>
      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        product={data as any}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </Link>
  );
};

export default ListCardMobile;
