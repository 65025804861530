import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.svg";
import logoLightImg from "images/logo-light.svg";
import { useSelector } from "react-redux";
import { RootState } from "store";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
}) => {
  const { catalogo } = useSelector((state: RootState) => state.catalogo.data);

  if (!catalogo) {
    return null;
  }

  return (
    <Link
      to={catalogo.slug}
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-[70px] sm:max-h-[70px] rounded-md ${
            imgLight ? "dark:hidden" : ""
          }`}
          src={catalogo.photo}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-[70px] sm:max-h-[70px] dark:block rounded-md"
          src={catalogo.photo}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
